import { createTheme } from "@mui/material";
import { esES } from "@mui/material/locale";

const darkTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 900,
        xl: 1200,
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#0176DE",
      },
      background: {
        default: "#fff",
        paper: "#fff",
      },
      text: {
        primary: "#000",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: "#173F8A",
          },
        },
      },
    },
  },
  esES
);

export default darkTheme;

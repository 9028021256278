import * as React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import AppRoutes from './routes';
import ErrorBoundaryComponent from './components/ErrorBoundaryComponent/ErrorBoundaryComponent';
import SnackComponent from './components/SnackComponent/SnackComponent';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Loading from './components/LoadingComponent/LoadingComponent';

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={'.'}
    >
      <ErrorBoundaryComponent>
        <Provider store={store}>
          <SnackComponent />
          <AppRoutes />
        </Provider>
      </ErrorBoundaryComponent>
    </CacheBuster>
  );
};

export default App;

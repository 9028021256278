import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import PrivateRoute from './PrivateRoutes';
import PublicRoute from './PublicRoutes';
import Loading from '../components/LoadingComponent/LoadingComponent';
import { signIn } from '../store/Auth/AuthActionsCreator';

const UnauthorizedPage = React.lazy(() =>
  import('../pages/UnauthorizedPage/UnauthorizedPage')
);

const NoMatchPage = React.lazy(() =>
  import('../pages/NoMatchPage/NoMatchPage')
);

const LoginPage = React.lazy(() => import('../pages/LoginPage/LoginPage'));

const LoginEmailPage = React.lazy(() =>
  import('../pages/LoginPage/LoginEmailPage')
);

const LoginJwtPage = React.lazy(() =>
  import('../pages/LoginPage/LoginJwtPage')
);

const MainLayoutPage = React.lazy(() =>
  import('../pages/MainLayoutPage/MainLayoutPage')
);

const AccountLayoutPage = React.lazy(() =>
  import('../pages/AccountLayoutPage/AccountLayoutPage')
);

const HomComponent = React.lazy(() =>
  import('../components/HomeComponent/HomeComponent')
);

const CoursesComponent = React.lazy(() =>
  import('../components/CoursesComponent/CoursesComponent')
);

const CourseLayoutPage = React.lazy(() =>
  import('../pages/CourseLayoutPage/CourseLayoutPage')
);

const CourseComponent = React.lazy(() =>
  import('../components/CourseComponent/CourseComponent/CourseComponent')
);

const CourseNewsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseNewsComponent/CourseNewsComponent'
  )
);

const CourseModulesComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseModulesComponent/CourseModulesComponent'
  )
);

const CourseModulesViewComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseModulesComponent/CourseModulesViewComponent'
  )
);

const CourseModulesBlocksSectionsViewComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseModulesComponent/CourseModulesBlocksSectionsViewComponent'
  )
);

const CourseModulesExercisesClassComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseModulesComponent/CourseModulesExercisesClassComponent'
  )
);

const CourseModulesExercisesComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseModulesComponent/CourseModulesExercisesComponent'
  )
);

const CourseCalendarComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseCalendarComponent/CourseCalendarComponent'
  )
);

const CourseRegulationComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseRegulationComponent/CourseRegulationComponent'
  )
);

const CourseEvaluationsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/CourseEvaluationsComponent/CourseEvaluationsComponent'
  )
);

const CourseAdminCalendarComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminCalendarComponent/CourseAdminCalendarComponent'
  )
);

const CourseAdminCalendarAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminCalendarComponent/CourseAdminCalendarAddComponent'
  )
);

const CourseAdminCalendarEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminCalendarComponent/CourseAdminCalendarEditComponent'
  )
);

const CourseAdminEvaluationsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminEvaluationsComponent/CourseAdminEvaluationsComponent'
  )
);

const CourseAdminExercisesClassComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesClassComponent/CourseAdminExercisesClassComponent'
  )
);

const CourseAdminExercisesClassAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesClassComponent/CourseAdminExercisesClassAddComponent'
  )
);
const CourseAdminExercisesClassEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesClassComponent/CourseAdminExercisesClassEditComponent'
  )
);

const CourseAdminExercisesComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesComponent/CourseAdminExercisesComponent'
  )
);

const CourseAdminExercisesAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesComponent/CourseAdminExercisesAddComponent'
  )
);

const CourseAdminExercisesEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminExercisesComponent/CourseAdminExercisesEditComponent'
  )
);

const CourseAdminModulesComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesComponent/CourseAdminModulesComponent'
  )
);

const CourseAdminModulesAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesComponent/CourseAdminModulesAddComponent'
  )
);

const CourseAdminModulesEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesComponent/CourseAdminModulesEditComponent'
  )
);

const CourseAdminModulesBlocksComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksComponent/CourseAdminModulesBlocksComponent'
  )
);

const CourseAdminModulesBlocksAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksComponent/CourseAdminModulesBlocksAddComponent'
  )
);

const CourseAdminModulesBlocksEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksComponent/CourseAdminModulesBlocksEditComponent'
  )
);

const CourseAdminModulesBlocksSectionsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksSectionsComponent/CourseAdminModulesBlocksSectionsComponent'
  )
);

const CourseAdminModulesBlocksSectionsAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksSectionsComponent/CourseAdminModulesBlocksSectionsAddComponent'
  )
);

const CourseAdminModulesBlocksSectionsEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminModulesBlocksSectionsComponent/CourseAdminModulesBlocksSectionsEditComponent'
  )
);

const CourseAdminStudentsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminStudentsComponent/CourseAdminStudentsComponent'
  )
);
const CourseAdminStudentsAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminStudentsComponent/CourseAdminStudentsAddComponent'
  )
);
const CourseAdminStudentsEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminStudentsComponent/CourseAdminStudentsEditComponent'
  )
);

const CourseAdminStudentsMasivoComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminStudentsComponent/CourseAdminStudentsMasivoComponent'
  )
);
const CourseAdminStudentsMasivoAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminStudentsComponent/CourseAdminStudentsMasivoAddComponent'
  )
);

const CourseAdminNewsComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminNewsComponent/CourseAdminNewsComponent'
  )
);
const CourseAdminNewsAddComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminNewsComponent/CourseAdminNewsAddComponent'
  )
);
const CourseAdminNewsEditComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminNewsComponent/CourseAdminNewsEditComponent'
  )
);

const CourseAdminRegulationComponent = React.lazy(() =>
  import(
    '../components/CourseComponent/AdminComponent/CourseAdminRegulationComponent/CourseAdminRegulationComponent'
  )
);

const SupportComponent = React.lazy(() =>
  import('../components/SupportComponent/SupportComponent')
);

const SupportPage = React.lazy(() => import('../pages/Support/SupportPage'));

const AdminLayoutPage = React.lazy(() =>
  import('../pages/AdminLayoutPage/AdminLayoutPage')
);

const AdminUsersComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersComponent/AdminUsersComponent'
  )
);
const AdminUsersAddComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersComponent/AdminUsersAddComponent'
  )
);
const AdminUsersEditComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersComponent/AdminUsersEditComponent'
  )
);

const AdminUsersMasivoComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersComponent/AdminUsersMasivoComponent'
  )
);

const AdminUsersMasivoAddComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersComponent/AdminUsersMasivoAddComponent'
  )
);

const AdminUsersRolesComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersRolesComponent/AdminUsersRolesComponent'
  )
);

const AdminUsersRolesAddComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersRolesComponent/AdminUsersRolesAddComponent'
  )
);

const AdminUsersRolesEditComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/UsersComponent/AdminUsersRolesComponent/AdminUsersRolesEditComponent'
  )
);

const AdminCoursesComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesComponent/AdminCoursesComponent'
  )
);
const AdminCoursesAddComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesComponent/AdminCoursesAddComponent'
  )
);
const AdminCoursesEditComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesComponent/AdminCoursesEditComponent'
  )
);

const AdminCoursesCopyComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesComponent/AdminCoursesCopyComponent'
  )
);

const AdminCoursesCourseComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesCourseComponent/AdminCoursesCourseComponent'
  )
);
const AdminCoursesCourseAddComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesCourseComponent/AdminCoursesCourseAddComponent'
  )
);
const AdminCoursesCourseEditComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/CoursesComponent/AdminCoursesCourseComponent/AdminCoursesCourseEditComponent'
  )
);

const AdminNotificationsComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/AdminNotificationsComponent/AdminNotificationsComponent'
  )
);

const AdminSupportComponent = React.lazy(() =>
  import(
    '../components/AdminComponent/AdminSupportComponent/AdminSupportComponent'
  )
);

const AppRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const course = useSelector((state) => state.nav.course);
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.nav.loading);

  try {
    if (auth.token) {
      const jwt = jwt_decode(auth.token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (jwt.exp < currentTime) {
        auth.token = '';
        window.localStorage.removeItem('_token');
      } else {
        if (auth.uid === '') {
          dispatch(signIn(auth.token));
          //socket.connectSocket();
        }
      }
    }
  } catch {
    auth.token = '';
    window.localStorage.removeItem('_token');
  }

  const paths = ['/', '/account', '/courses', '/unauthorized'];
  const pathsCourses = [
    '/courses/:id',
    '/courses/:id/news',
    '/courses/:id/modules',
    '/courses/:id/modules/:idm',
    '/courses/:id/modules/:idm/blocks/:idb/sections/:ids',
    '/courses/:id/modules/:idm/blocks/:idb/sections/:ids/exercisesclass',
    '/courses/:id/modules/:idm/blocks/:idb/sections/:ids/exercises',
    '/courses/:id/calendar',
    '/courses/:id/regulation',
    '/courses/:id/evaluations',

    '/courses/:id/administrator/students',
    '/courses/:id/administrator/students/add',
    '/courses/:id/administrator/students/edit/:ide',
    '/courses/:id/administrator/students/masivo',
    '/courses/:id/administrator/students/masivo/add',

    '/courses/:id/administrator/news',
    '/courses/:id/administrator/news/add',
    '/courses/:id/administrator/news/edit/:idn',
    '/courses/:id/administrator/calendar',
    '/courses/:id/administrator/calendar/add',
    '/courses/:id/administrator/calendar/edit/:idc',
    '/courses/:id/administrator/regulation',
    '/courses/:id/administrator/evaluations',
    '/courses/:id/administrator/modules/modules',
    '/courses/:id/administrator/modules/modules/add',
    '/courses/:id/administrator/modules/modules/edit/:idm',
    '/courses/:id/administrator/modules/blocks',
    '/courses/:id/administrator/modules/blocks/add',
    '/courses/:id/administrator/modules/blocks/edit/:idm/:idb',
    '/courses/:id/administrator/modules/sections',
    '/courses/:id/administrator/modules/sections/add',
    '/courses/:id/administrator/modules/sections/edit/:idm/:idb/:ids',
    '/courses/:id/administrator/modules/exercisesclass',
    '/courses/:id/administrator/modules/exercisesclass/add',
    '/courses/:id/administrator/modules/exercisesclass/edit/:idm/:idec',
    '/courses/:id/administrator/modules/exercises',
    '/courses/:id/administrator/modules/exercises/add',
    '/courses/:id/administrator/modules/exercises/edit/:idm/:ide',
  ];
  const pathsAdmin = [
    '/admin',
    '/admin/users',
    '/admin/users/add',
    '/admin/users/edit/:id',
    '/admin/users/masivo',
    '/admin/users/masivo/add',
    '/admin/users/roles',
    '/admin/users/roles/add',
    '/admin/users/roles/edit/:id',
    '/admin/courses',
    '/admin/courses/add',
    '/admin/courses/edit/:id',
    '/admin/courses/copy/:id',
    '/admin/courses/course',
    '/admin/courses/course/add',
    '/admin/courses/course/edit/:id',
    '/admin/notifications',
    '/admin/support',
    '/admin/courses',
    '/admin/courses/copy-course/:id',
  ];

  return (
    <BrowserRouter>
      {isLoading && <Loading />}
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={paths}>
            <MainLayoutPage>
              <Switch>
                <PrivateRoute exact path="/" page={HomComponent} auth={auth} />
                <PrivateRoute
                  exact
                  path="/account"
                  page={AccountLayoutPage}
                  auth={auth}
                />

                <PrivateRoute
                  exact
                  path="/courses"
                  page={CoursesComponent}
                  auth={auth}
                />

                <PrivateRoute
                  exact
                  path="/support"
                  auth={auth}
                  page={SupportComponent}
                />

                <PrivateRoute
                  exact
                  path="/unauthorized"
                  auth={auth}
                  page={UnauthorizedPage}
                />
              </Switch>
            </MainLayoutPage>
          </Route>

          <Route exact path={pathsCourses}>
            <CourseLayoutPage>
              <Switch>
                <PrivateRoute
                  exact
                  path="/courses/:id"
                  page={CourseComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/news"
                  page={CourseNewsComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/modules"
                  page={CourseModulesComponent}
                  auth={auth}
                />

                <PrivateRoute
                  exact
                  path="/courses/:id/modules/:idm"
                  page={CourseModulesViewComponent}
                  auth={auth}
                />

                <PrivateRoute
                  exact
                  path="/courses/:id/modules/:idm/blocks/:idb/sections/:ids"
                  page={CourseModulesBlocksSectionsViewComponent}
                  auth={auth}
                />

                <PrivateRoute
                  exact
                  path="/courses/:id/modules/:idm/blocks/:idb/sections/:ids/exercisesclass"
                  page={CourseModulesExercisesClassComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/modules/:idm/blocks/:idb/sections/:ids/exercises"
                  page={CourseModulesExercisesComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/calendar"
                  page={CourseCalendarComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/regulation"
                  page={CourseRegulationComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/evaluations"
                  page={CourseEvaluationsComponent}
                  auth={auth}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/students"
                  page={CourseAdminStudentsComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/students/add"
                  page={CourseAdminStudentsAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/students/edit/:ide"
                  page={CourseAdminStudentsEditComponent}
                  auth={auth}
                  course={course}
                />

                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/students/masivo"
                  page={CourseAdminStudentsMasivoComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/students/masivo/add"
                  page={CourseAdminStudentsMasivoAddComponent}
                  auth={auth}
                  course={course}
                />

                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/news"
                  page={CourseAdminNewsComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/news/add"
                  page={CourseAdminNewsAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/news/edit/:idn"
                  page={CourseAdminNewsEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/modules"
                  page={CourseAdminModulesComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/modules/add"
                  page={CourseAdminModulesAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/modules/edit/:idm"
                  page={CourseAdminModulesEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/blocks"
                  page={CourseAdminModulesBlocksComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/blocks/add"
                  page={CourseAdminModulesBlocksAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/blocks/edit/:idm/:idb"
                  page={CourseAdminModulesBlocksEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/sections"
                  page={CourseAdminModulesBlocksSectionsComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/sections/add"
                  page={CourseAdminModulesBlocksSectionsAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/sections/edit/:idm/:idb/:ids"
                  page={CourseAdminModulesBlocksSectionsEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercisesclass"
                  page={CourseAdminExercisesClassComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercisesclass/add"
                  page={CourseAdminExercisesClassAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercisesclass/edit/:idm/:idec"
                  page={CourseAdminExercisesClassEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercises"
                  page={CourseAdminExercisesComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercises/add"
                  page={CourseAdminExercisesAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/modules/exercises/edit/:idm/:ide"
                  page={CourseAdminExercisesEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/calendar"
                  page={CourseAdminCalendarComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/calendar/add"
                  page={CourseAdminCalendarAddComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/calendar/edit/:idc"
                  page={CourseAdminCalendarEditComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/regulation"
                  page={CourseAdminRegulationComponent}
                  auth={auth}
                  course={course}
                />
                <PrivateRoute
                  exact
                  path="/courses/:id/administrator/evaluations"
                  page={CourseAdminEvaluationsComponent}
                  auth={auth}
                  course={course}
                />
              </Switch>
            </CourseLayoutPage>
          </Route>

          <Route exact path={pathsAdmin}>
            <AdminLayoutPage>
              <Switch>
                <Route exact path="/admin">
                  <Redirect to="/admin/users" />
                </Route>
                <PrivateRoute
                  exact
                  path="/admin/users"
                  page={AdminUsersComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/add"
                  page={AdminUsersAddComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/edit/:id"
                  page={AdminUsersEditComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/masivo"
                  page={AdminUsersMasivoComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/masivo/add"
                  page={AdminUsersMasivoAddComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/roles"
                  page={AdminUsersRolesComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/roles/add"
                  page={AdminUsersRolesAddComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/users/roles/edit/:id"
                  page={AdminUsersRolesEditComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses"
                  page={AdminCoursesComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/add"
                  page={AdminCoursesAddComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/edit/:id"
                  page={AdminCoursesEditComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/copy/:id"
                  page={AdminCoursesCopyComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/course"
                  page={AdminCoursesCourseComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/course/add"
                  page={AdminCoursesCourseAddComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/courses/course/edit/:id"
                  page={AdminCoursesCourseEditComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/notifications"
                  page={AdminNotificationsComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
                <PrivateRoute
                  exact
                  path="/admin/support"
                  page={AdminSupportComponent}
                  auth={auth}
                  role={'ADMINISTRADOR'}
                />
              </Switch>
            </AdminLayoutPage>
          </Route>

          <PublicRoute
            exact
            path="/login/checkToken"
            page={LoginEmailPage}
            auth={auth}
          />
          <PublicRoute
            exact
            path="/login/jwt/checkToken"
            page={LoginJwtPage}
            auth={auth}
          />
          <PublicRoute exact path="/support" page={SupportPage} auth={auth} />
          <PublicRoute exact path="/login" page={LoginPage} auth={auth} />
          <Route path="*" component={NoMatchPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;

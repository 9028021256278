import React, { Component } from 'react';
import './ErrorBoundaryomponent.scss';
import axios from '../../services/axios';

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    const message = `${error.message} / path:${window.location.pathname}`;
    axios
      .post(`/logFronts`, { error: message })
      .catch((error) => console.log(error));
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <section className="centered">
            <h1>Error</h1>
            <div className="container">
              <h2 className="error-title">
                Woops! <br />
                Algo Salio Mal :(
              </h2>
              <h3 className="error-subtitle">
                Intente actualizando su navegador o borrando el caché!
              </h3>
            </div>
          </section>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryComponent;
